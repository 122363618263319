<template>
  <div id="layout-mobile">
    <keep-alive>
      <router-view />
    </keep-alive>
    
    <van-tabbar route v-show="$store.state.token">
      <van-tabbar-item icon="home-o" replace  to="/home">推荐</van-tabbar-item>
      <van-tabbar-item icon="search" replace to="/Candlestick">行情</van-tabbar-item>
      <van-tabbar-item icon="friends-o" replace  to="/research">碳研社</van-tabbar-item>
      <!-- <van-tabbar-item icon="setting-o" replace to="/knowledge">碳知识</van-tabbar-item> -->
      <van-tabbar-item icon="user-circle-o" replace  to="/tool">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "DoubleCIndex",

  data() {
    return {
    };
  },
  activated() {},

  methods: {},
};
</script>

<style lang="less">
#layout-mobile {
  & .van-goods-action {
    // display: flex;
  }
  // height: 100%;
  // padding: 20px 0 0;
  &>div{
    &.show-tabbar{
      height: calc(100% - 50px);
    }
  }
}
</style>
